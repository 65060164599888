import React from "react";

const Plan = () => {
    return (
        <svg
            width="57"
            height="58"
            viewBox="0 0 57 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 5.25C13.7533 5.25 9.5 9.50331 9.5 14.75V48.0018C9.5 52.0001 14.1382 54.2096 17.2429 51.6903L27.0035 43.7703C27.8757 43.0626 29.1243 43.0626 29.9965 43.7703L39.7569 51.6903C42.8616 54.2096 47.4999 52 47.4999 48.0018V14.75C47.4999 9.50329 43.2466 5.25 37.9999 5.25H19Z"
                fill="#E1F5FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.3044 17.8206C38.2319 18.7481 38.2319 20.2519 37.3044 21.1794L27.8044 30.6794C26.8769 31.6069 25.3731 31.6069 24.4456 30.6794L19.6956 25.9294C18.7681 25.0019 18.7681 23.4981 19.6956 22.5706C20.6231 21.6431 22.1269 21.6431 23.0544 22.5706L26.125 25.6412L33.9456 17.8206C34.8731 16.8931 36.3769 16.8931 37.3044 17.8206Z"
                fill="#4E8AF4"
            />
        </svg>
    );
};

export default Plan;
