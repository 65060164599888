export const home = {
  title: 'Synctrack - Auto Sync PayPal tracking | Faster Funds Release',
  description:
    'Sync tracking to PayPal/Stripe, and process all orders within 1 year. Multiple stores & Unlimited payment account. Verify Transaction ID. Avoid disputes & money on hold',
};

export const product = {
  title: 'Synctrack - the fastest way to get your payment on Paypal',
  description:
    'By choosing our superb Synctrack tool, we believe that you can find it completely convenient to add all the tracking information to Paypal and Stripe.',
};

export const affiliate = {
  title: 'Synctrack - Affiliate',
  description:
    'By choosing our superb Synctrack tool, we believe that you can find it completely convenient to add all the tracking information to Paypal and Stripe.',
};

export const pricing = {
  title: 'Synctrack Pricing - start free',
  description:
    'Synctrack has free plan for small business. Grow your business, start now!',
};

export const faq = {
  title: 'Synctrack - frequently asked questions',
  description:
    'What is Synctrack and how does it work? The FAQ section contains all of the answers to your questions about Paypal synchronization.',
};

export const blogs = {
  title: 'Synctrack - more guidance on Blogs you may need!',
  description:
    'We give you all the crucial, up-to-date information that helps you know more about our product, making sure you will be satisfied.',
};

export const about = {
  title:
    'Synctrack - connects your Shopify and PayPal accounts to automatically',
  description:
    'Synctrack’s team always tries our best to give you the ideal solutions, therefore you can rely on us and improve the efficiency of your job.',
};

export const contact = {
  title: 'Synctrack - contact us',
  description:
    'We love to hear from you. Send us a message and we’ll respond as soon as possible',
};

export const customer = {
  title: 'Synctrack - customer',
  description:
    'We love to hear from you. Send us a message and we’ll respond as soon as possible',
};

export const partners = {
  title: 'Synctrack - partners',
  description: "Be come a synctrack's partner",
};
