import React from "react";
import Falcon from "../SVGS/icons/Falcon";
import ready from "../../public/images/homepage/ready2.png";
import { useStore, actions } from "../../store";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "next/future/image";

const GetReady = () => {
  const [state, dispatch] = useStore();
  return (
    <div className="omg-get-ready-wrapper ptb-3rem">
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <div className="omg-get-ready-inner">
          <div className="container">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <div>
                  <h2 className="display-2">
                    Let orders synced protect your money
                  </h2>
                  <p className="body-lg">
                    Start syncing now and gain control over your order-syncing
                    process.
                  </p>
                  <button
                    className="btn-second mt-3"
                    onClick={() => {
                      window.open(
                        "https://apps.shopify.com/synctrack?utm_source=website&utm_medium=Footer&utm_campaign=website",
                        "_blank"
                      );
                      GAEventsTracker("Button/click", "Free Sync now");
                    }}
                  >
                    <Falcon />
                    Free Sync now
                  </button>
                </div>
              </div>
              <div className="col-6 d-flex align-items-center">
                <Image
                  src="/images/homepage/ready2.png"
                  sizes="(max-width: 768px) 100vw, 648px"
                  width={648}
                  height={609}
                  alt="get ready to started synctrack add tracking paypal"
                  className="optimize-img"
                />
              </div>
            </div>
          </div>
          <div className="omg-get-ready-decor"></div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default GetReady;
