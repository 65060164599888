import React from "react";

const Cancel = () => {
    return (
        <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 2.5C8.59644 2.5 3 8.09644 3 15C3 21.9036 8.59644 27.5 15.5 27.5C22.4036 27.5 28 21.9036 28 15C28 8.09644 22.4036 2.5 15.5 2.5Z"
                fill="#E0E0E0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8661 10.3661C11.3543 9.87796 12.1457 9.87796 12.6339 10.3661L15.5 13.2322L18.3661 10.3661C18.8543 9.87796 19.6457 9.87796 20.1339 10.3661C20.622 10.8543 20.622 11.6457 20.1339 12.1339L17.2678 15L20.1339 17.8661C20.622 18.3543 20.622 19.1457 20.1339 19.6339C19.6457 20.122 18.8543 20.122 18.3661 19.6339L15.5 16.7678L12.6339 19.6339C12.1457 20.122 11.3543 20.122 10.8661 19.6339C10.378 19.1457 10.378 18.3543 10.8661 17.8661L13.7322 15L10.8661 12.1339C10.378 11.6457 10.378 10.8543 10.8661 10.3661Z"
                fill="black"
            />
        </svg>
    );
};

export default Cancel;
