import React from "react";

const CheckBlue = () => {
    return (
        <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.25 11.75C4.25 8.94974 4.25 7.54961 4.79497 6.48005C5.27433 5.53924 6.03924 4.77433 6.98005 4.29497C8.04961 3.75 9.44974 3.75 12.25 3.75H18.75C21.5503 3.75 22.9504 3.75 24.02 4.29497C24.9608 4.77433 25.7257 5.53924 26.205 6.48005C26.75 7.54961 26.75 8.94974 26.75 11.75V18.25C26.75 21.0503 26.75 22.4504 26.205 23.52C25.7257 24.4608 24.9608 25.2257 24.02 25.705C22.9504 26.25 21.5503 26.25 18.75 26.25H12.25C9.44974 26.25 8.04961 26.25 6.98005 25.705C6.03924 25.2257 5.27433 24.4608 4.79497 23.52C4.25 22.4504 4.25 21.0503 4.25 18.25V11.75Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1339 11.6161C20.622 12.1043 20.622 12.8957 20.1339 13.3839L15.1339 18.3839C14.6457 18.872 13.8543 18.872 13.3661 18.3839L10.8661 15.8839C10.378 15.3957 10.378 14.6043 10.8661 14.1161C11.3543 13.628 12.1457 13.628 12.6339 14.1161L14.25 15.7322L18.3661 11.6161C18.8543 11.128 19.6457 11.128 20.1339 11.6161Z"
                fill="#E1F5FF"
            />
        </svg>
    );
};

export default CheckBlue;
